<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <ValidationProvider
          vid="nombre"
          name="nombre"
          rules="required|min:3|max:30"
          v-slot="{ errors }"
        >
          <vs-input
            v-model="plant.name"
            class="w-full"
            placeholder="Nombre"
            label="Nombre"
            data-test="namePlantFormInput"
          ></vs-input>
          <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <ValidationProvider
          name="franquicia"
          rules="required"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <vs-select
            class="w-full {'is-invalid': !!errors.length}"
            autocomplete
            v-model="plant.franchise_id"
            label="Franquicia"
            data-test="franchisePlantFormInput"
            placeholder="Selecciona una franquicia"
          >
            <vs-select-item :value="null" text="Seleccione una franquicia" />
            <vs-select-item
              v-for="(item, index) in franchises"
              :key="index"
              :value="item.id"
              :text="item.name"
            />
          </vs-select>
          <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <!-- <label class="text-sm text-san-juan font-bold">Hora inicio</label> -->
        <ValidationProvider
          name="Hora inicio"
          rules="required"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <!-- <flat-pickr
            :config="configdateTimePicker"
            v-model="plant.start_hour"
            class="w-full"
            label="Hora inicio"
            placeholder="00:00"
            data-test="startHourPlantFormInput"
          /> -->
          <vs-input
            class="w-full"
            type="time"
            v-model="plant.start_hour"
            label="Hora inicio"
          />
          <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <!-- <label class="text-sm text-san-juan font-bold">Hora inicio</label> -->
        <ValidationProvider
          name="Hora cierre"
          rules="required"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <!-- <flat-pickr
            :config="configdateTimePicker"
            v-model="plant.end_hour"
            class="w-full"
            label="Hora cierre"
            data-test="endHourPlantFormInput"
            placeholder="00:00"
          /> -->
          <vs-input
            class="w-full"
            type="time"
            v-model="plant.end_hour"
            label="Hora cierre"
          />
          <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="vx-col w-full">
        <ValidationProvider
          name="Descripción"
          rules="required"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <vs-input
            v-model="plant.description"
            class="w-full"
            placeholder="Descripción"
            label="Descripción"
            data-test="descriptionPlantFormInput"
          ></vs-input>
          <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
    </div>
    <vs-divider></vs-divider>
    <div
      class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-4"
    >
      <div v-for="dia in dias" :key="dia.name" class="flex flex-col">
        <p class="text-san-juan font-bold text-lg">{{ dia.name }}</p>
        <vs-divider></vs-divider>
        <ValidationProvider
          name="Cantidad de Cupos"
          rules="required"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <vs-input
            v-model.number="plant.availability[dia.key_seats]"
            class="w-full"
            placeholder="#"
            label="Cantidad de Cupos"
            :data-test="`qtySeats${dia.key_seats}PlantFormInput`"
          ></vs-input>
          <div class="block text-danger text-sm leading-tight">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="Valor"
          rules="required"
          v-slot="{ errors }"
          tag="div"
          class="form-group"
        >
          <vs-input
            v-model.number="plant.availability[dia.key_amount]"
            class="w-full"
            placeholder="$"
            label="Valor"
            :data-test="`qtySeats${dia.key_amount}PlantFormInput`"
          ></vs-input>
          <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import { FRANCHISES } from '@/graphql/queries';
export default {
  name: 'PlantForm',
  components: {
    ValidationProvider,
  },
  props: {
    value: Object,
  },
  computed: {
    plant: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  data: () => ({
    franchises: [],
    configdateTimePicker: {
      enableTime: true,
      enableSeconds: true,
      noCalendar: true,
    },
    dias: [
      {
        name: 'Lunes',
        key_seats: 'monday_seats',
        key_amount: 'monday_value',
        cantidad: null,
        valor: null,
      },
      {
        name: 'Martes',
        key_seats: 'tuesday_seats',
        key_amount: 'tuesday_value',
        cantidad: null,
        valor: null,
      },
      {
        name: 'Miércoles',
        key_seats: 'wednesday_seats',
        key_amount: 'wednesday_value',
        cantidad: null,
        valor: null,
      },
      {
        name: 'Jueves',
        key_seats: 'thursday_seats',
        key_amount: 'thursday_value',
        cantidad: null,
        valor: null,
      },
      {
        name: 'Viernes',
        key_seats: 'friday_seats',
        key_amount: 'friday_value',
        cantidad: null,
        valor: null,
      },
      {
        name: 'Sábado',
        key_seats: 'saturday_seats',
        key_amount: 'saturday_value',
        cantidad: null,
        valor: null,
      },
      {
        name: 'Domingo',
        key_seats: 'sunday_seats',
        key_amount: 'sunday_value',
        cantidad: null,
        valor: null,
      },
    ],
  }),
  mounted() {
    this.getFranchises();
  },
  methods: {
    async getFranchises() {
      const { data } = await this.$apollo.query({
        query: FRANCHISES,
      });
      this.franchises = data.franchises;
    },
  },
};
</script>

<style scoped></style>
