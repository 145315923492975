var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('ValidationProvider',{attrs:{"vid":"nombre","name":"nombre","rules":"required|min:3|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"Nombre","label":"Nombre","data-test":"namePlantFormInput"},model:{value:(_vm.plant.name),callback:function ($$v) {_vm.$set(_vm.plant, "name", $$v)},expression:"plant.name"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"franquicia","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full {'is-invalid': !!errors.length}",attrs:{"autocomplete":"","label":"Franquicia","data-test":"franchisePlantFormInput","placeholder":"Selecciona una franquicia"},model:{value:(_vm.plant.franchise_id),callback:function ($$v) {_vm.$set(_vm.plant, "franchise_id", $$v)},expression:"plant.franchise_id"}},[_c('vs-select-item',{attrs:{"value":null,"text":"Seleccione una franquicia"}}),_vm._l((_vm.franchises),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.name}})})],2),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Hora inicio","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"type":"time","label":"Hora inicio"},model:{value:(_vm.plant.start_hour),callback:function ($$v) {_vm.$set(_vm.plant, "start_hour", $$v)},expression:"plant.start_hour"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Hora cierre","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"type":"time","label":"Hora cierre"},model:{value:(_vm.plant.end_hour),callback:function ($$v) {_vm.$set(_vm.plant, "end_hour", $$v)},expression:"plant.end_hour"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Descripción","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"Descripción","label":"Descripción","data-test":"descriptionPlantFormInput"},model:{value:(_vm.plant.description),callback:function ($$v) {_vm.$set(_vm.plant, "description", $$v)},expression:"plant.description"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('vs-divider'),_c('div',{staticClass:"grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-4"},_vm._l((_vm.dias),function(dia){return _c('div',{key:dia.name,staticClass:"flex flex-col"},[_c('p',{staticClass:"text-san-juan font-bold text-lg"},[_vm._v(_vm._s(dia.name))]),_c('vs-divider'),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Cantidad de Cupos","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"#","label":"Cantidad de Cupos","data-test":("qtySeats" + (dia.key_seats) + "PlantFormInput")},model:{value:(_vm.plant.availability[dia.key_seats]),callback:function ($$v) {_vm.$set(_vm.plant.availability, dia.key_seats, _vm._n($$v))},expression:"plant.availability[dia.key_seats]"}}),_c('div',{staticClass:"block text-danger text-sm leading-tight"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Valor","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"$","label":"Valor","data-test":("qtySeats" + (dia.key_amount) + "PlantFormInput")},model:{value:(_vm.plant.availability[dia.key_amount]),callback:function ($$v) {_vm.$set(_vm.plant.availability, dia.key_amount, _vm._n($$v))},expression:"plant.availability[dia.key_amount]"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }