<template>
  <div>
    <vx-card>
      <ValidationObserver ref="observerPlant" v-slot="{ invalid }">
        <plant-form v-model="newPlant" />
        <div class="flex flex-row-reverse m-6">
          <vs-button @click="submit" class="mb-4 md:mb-0" :disabled="invalid"
            >Guardar</vs-button
          >
          <vs-button @click="regresar" class="mr-2" type="flat"
            >Descartar</vs-button
          >
        </div>
      </ValidationObserver>
    </vx-card>
  </div>
</template>

<script>
import PlantForm from '@/components/plantas/PlantForm';
import { CREATE_PLANT } from '@/graphql/mutations';
import { PLANTS_PAGINATED } from '@/graphql/queries';

export default {
  name: 'AddPlant',
  components: {
    PlantForm,
  },
  data: () => ({
    newPlant: {
      availability: {},
      start_hour: null,
      end_hour: null,
    },
  }),
  methods: {
    submit() {
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: CREATE_PLANT,
          variables: {
            input: this.newPlant,
          },
          update: (cache, { data: { createPlant } }) => {
            try {
              const data = cache.readQuery({
                query: PLANTS_PAGINATED,
                variables: {
                  page: 1,
                  first: 15,
                },
              });
              console.log(data);
              data.plantsPaginated.data.unshift(createPlant);
              cache.writeQuery({
                query: PLANTS_PAGINATED,
                variables: {
                  page: 1,
                  first: 15,
                },
                data,
              });
            } catch {
              console.log();
            }
          },
        })
        .then(() => {
          this.newPlant = {
            availability: {},
            start_hour: null,
            end_hour: null,
          };
          this.$vs.notify({
            color: 'success',
            title: 'Planta creada',
            text: 'Se creo la planta sactifactoriamente',
          });
          this.$router.push('/plantas');
        })
        .catch((error) => {
          this.$vs.notify({
            color: 'danger',
            title: 'Error al crear la planta',
            text: `${error}`,
          });
        });
    },
    regresar() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
